// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}


// 根据参数日期获取具体日期信息
export const formatDate = function (date = new Date()) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1);
  let day = date.getDate();
  let week = ['天', '一', '二', '三', '四', '五', '六'][date.getDay()];

  const dateInfo = {
    date: new Date(date),
    dateStr: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
    year,
    month,
    day,
    week,
    isToday: false
  }

  const today = new Date();
  // 判断是否为当天
  if (today.getFullYear() === year && (today.getMonth() + 1) === month && today.getDate() === day) {
    dateInfo['isToday'] = true;
  }
  return dateInfo;
};

// 根据基准日期，获取长度为dayLenth的日期数组
export const setDate = function (date = new Date(), step = 7) {
  let weekData = [];
  date = new Date();
  for (let i = 0; i < step; i++) {
    weekData.push(formatDate(i == 0 ? date : getDateByDate(date, 1)))
  }
  return weekData;
};

// 根据基准日期获取前后某天的日期对象
export const getDateByDate = function (date = new Date(), range = 0) {
  date.setDate(date.getDate() + range);
  return date;
};

/* 
  获取以baselineDate所在周的一周、前一周、下一周的日期和星期信息(切换周期也可通过参数dayLenth自行设置)
  baselineDate: 设置的基准日期(返回的日期列表的第一个日期)
  range: 以 baselineDate 为基准日期的前后天数范围(如基准日期的range为0，需要返回前7天日期，则range为-7，后7天则range为7)
  step: 需要获取的日期信息周期天数，默认获取baselineDate所在周的一周日期信息
 */
export const getWeekDate = ({ baselineDate = new Date(), range = 0, step = 7 }) => {
  return setDate(getDateByDate(baselineDate, range), step);
};


//身份证号码校验检查
export function checkIDCardNum(code) {
  var city = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江 ",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北 ",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏 ",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外 "
  };
  var tip = "";
  var pass = true;

  if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
    tip = "身份证号格式错误";
    pass = false;
  } else if (!city[code.substr(0, 2)]) {
    tip = "地址编码错误";
    pass = false;
  } else {
    //18位身份证需要验证最后一位校验位
    if (code.length == 18) {
      code = code.split('');
      //∑(ai×Wi)(mod 11)
      //加权因子
      var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      //校验位
      var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
      var sum = 0;
      var ai = 0;
      var wi = 0;
      for (var i = 0; i < 17; i++) {
        ai = code[i];
        wi = factor[i];
        sum += ai * wi;
      }
      var last = parity[sum % 11];
      if (parity[sum % 11] != code[17]) {
        tip = "校验位错误";
        pass = false;
      }
    }
  }
  return pass;
}

